/**
 * @Author: connor_ck nan.cheng@dibcn.com
 * @Date: 2023-11-16 20:08:24
 * @LastEditors: connor_ck nan.cheng@dibcn.com
 * @LastEditTime: 2023-11-27 12:12:59
 * @FilePath: /rd-pc/src/router/index.js
 * @Description: 
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
    path: '/',
    component: () => import("@/views/home/index.vue"),

  },
	{
		path:"/job",
		component:() => import("@/views/job/index.vue")
	},
	{
		path:"/worker",
		component:() => import("@/views/worker/index.vue")
	},
	{
		path:"/weakCurrent",
		component:() => import("@/views/weak-current/index.vue")
	},
	
	{
		path:"/jobDetail",
		component:() => import("@/views/job-detail/index.vue")
	},
	{
		path:"/workerDetail",
		component:() => import("@/views/worker-detail/index.vue")
	},
	{
		path:"/weakCurrentDetail",
		component:() => import("@/views/weak-current-detail/index.vue")
	},
	{
		path:"/city",
		component:() => import("@/views/city/index.vue")
	},

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
