/**
 * @Author: connor_ck nan.cheng@dibcn.com
 * @Date: 2023-11-23 21:52:10
 * @LastEditors: connor_ck nan.cheng@dibcn.com
 * @LastEditTime: 2023-11-26 21:28:28
 * @FilePath: /rd-pc/src/api/home.js
 * @Description: 
 */
import { service } from '../service/index'


export function getList(data = {}) {
  return service.post({
    url: '/api',
    data:{
			...data,

			session_key:"",
			uid:0,
			user_id:0,
		}
  })
}


export function getCode(data){
	return service.post({
		url:"/api/Login/SmsSend",

		data
	})
}

export function getLogin(data) {
	return service.post({
		url:"/api/Index/index",
		data
	})
}

export function getDetail(data) {
	return service.post({
		url:"/api",
		data:{
			method:"users.look_info",
			...data
		}
	})
}

export function getUserInfo(data) {
	return service.post({
		url:"/api",
		data:{
			method:"users.userInfo",
			...data
		}
	})
}