import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css'
import { Button, Message,Upload, Row, Col, Select, Option, Form, FormItem, InputNumber, Input, Tabs, TabPane, Checkbox, Pagination , Dialog} from 'element-ui'
const components = [Button,Upload, Message, Row, Col, Select, Option, Form, FormItem, InputNumber, Input, Tabs, TabPane, Checkbox, Pagination, Dialog]

export function registerElement() {
  Vue.prototype.$message = Message

  for (const component of components) {
    Vue.component(component.name, component)
  }
}
