/**
 * @Author: connor_ck nan.cheng@dibcn.com
 * @Date: 2023-11-23 21:45:56
 * @LastEditors: connor_ck nan.cheng@dibcn.com
 * @LastEditTime: 2023-11-26 21:29:14
 * @FilePath: /rd-pc/src/store/index.js
 * @Description: 
 */
import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
		city:["全国"],
		isLogin:false, // 是否登录
		userInfo:{},
		userMessage: {},
	},
  mutations: {
		changeCity(state, value) {
			state.city= value;
		},
		changeIsLogin(state, value) {
			state.isLogin = value
		},
		changeUserInfo(state, value) {
			state.userInfo = value
		},
		changeUserMessage(state, value) {
			state.userMessage = value
		}
	},
  actions: {},
  modules: {},

  plugins:[createPersistedState()]
})
