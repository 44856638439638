<template>
  <div id="app" style="width: 100%; height: 100%">
    <div class="main_wrapper">
      <div class="header_wrapper">
        <div class="header_content">
          <div class="header_left">
            <a target="_blank" href="/">
              <img class="header_logo" src="@/assets/images/head-img.png" />
            </a>
            <span class="header_address">
              <i
                style="
                  color: #42c088 !important;
                  background-color: #fef6f5 !important;
                  font-weight: 600;
                  margin-right: 6px;
                "
                class="el-icon-location-outline"
              ></i>
              <span>{{ city[city.length - 1] }}</span>
            </span>
            <span style="color: #42c088; margin-right: 60px; cursor: pointer" @click="clickChangeCity">[切换城市]</span>
            <div class="header_tabs">
              <div class="tab_item" @click="clickTab(item)" v-for="(item, index) in tabList" :key="index">
                <div class="tab_text" :style="{ color: $route.path == item.path ? '#42c088' : '#000' }">
                  {{ item.name }}
                </div>
                <div class="tab_active" v-show="$route.path == item.path"></div>
              </div>
            </div>
          </div>
          <div class="header_right">
            <p class="header_logout" @click="clickLogin" v-if="!isLogin">登录丨注册</p>
            <div class="user-msg" v-else>
              <div class="icon-style">
                <img :src="userMessage.avatar" class="user_logo" />
              </div>
              <span class="user_name">{{ userMessage.username || '' }}</span>
              <span style="font-size: 14px; color: #818181; cursor: pointer" @click="clickOut">退出登录</span>
            </div>
          </div>
        </div>
      </div>
      <router-view />
      <div class="footer_wrapper">
        <div class="footer_content">
          <div class="footer_left">
            <div style="position: relative; display: inline-block">
              <img src="@/assets/images/footer-img.png" class="footer_logo" />
              <p>电话: 15051606967</p>
              <p>地址: 江西省赣州市南康区南水新区E-08、E-09地块</p>
              <p>网址: www.rdb666.xyz</p>
            </div>
          </div>
          <div class="footer_right">
            <div class="footer_right_item">
              <img src="@/assets/images/app.png" class="right_item_img" />
              <span>扫码下载APP</span>
            </div>
            <div class="footer_right_item">
              <img src="@/assets/images/xcx.png" class="right_item_img" />
              <span>扫码打开小程序</span>
            </div>
            <div class="footer_right_item">
              <img src="@/assets/images/gzh.png" class="right_item_img" />
              <span>扫码关注公众号</span>
            </div>
          </div>
        </div>
        <div class="footer_bottom">
          <div class="footer_bottom_content">
            <div>
              <!-- Copyright 2017-2023 版权所有
              成都鱼泡科技有限公司&nbsp;&nbsp;<a>赣ICP备2021009216号-1</a>&nbsp;&nbsp;<a> 赣公网安备36070002000261号 </a>&nbsp;&nbsp;<a>服务协议</a>&nbsp;&nbsp;<a>
                川B2-20211219</a
              > -->
              Copyright 2017-2023&nbsp;&nbsp;<a>赣ICP备2021009216号-1</a>&nbsp;&nbsp;<a> 赣公网安备36070002000261号 </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loginModel ref="loginModelRef" />
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import loginModel from '@/components/loginModel'
export default {
  data() {
    return {
      tabList: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '找活干',
          path: '/job'
        },
        {
          name: '找工人',
          path: '/worker'
        },
        {
          name: '弱电链',
          path: '/weakCurrent'
        }
      ]
      // currentTab: '/'
    }
  },
  components: {
    loginModel
  },
  computed: {
    ...mapState({
      city: (state) => state.city,
      isLogin: (state) => state.isLogin,
      userMessage: (state) => state.userMessage
    })
  },
  methods: {
    ...mapMutations(['changeIsLogin', 'changeUserInfo']),
    // 点击了退出登录
    clickOut() {
      this.changeIsLogin(false)
      this.$message('退出成功')
    },
    clickTab(item) {
      const url = this.$router.resolve({ path: item.path })
      window.open(url.href, '_blank')
      // this.currentTab = item.path
      // this.$router.push(item.path)
    },
    clickLogin() {
      this.$refs.loginModelRef.dialogVisible = true
    },
    clickChangeCity() {
      const url = this.$router.resolve({ path: '/city' })
      window.open(url.href, '_blank')
    }
  }
}
</script>
<style lang="less">
.main_wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 64px;
  // padding-bottom: 278px;
  .header_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    min-width: 1200px;
    min-height: 64px;
    background: #fff;
    .header_content {
      width: 1200px;
      height: 64px;
      margin: 0 auto;
      font-size: 16px;
      letter-spacing: 0;
      text-align: justify;
      font-weight: 400;
      line-height: 44px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .header_left {
        display: flex;
        .header_logo {
          width: 99px;
          height: 44px;
          margin-right: 34px;
        }
        .header_address {
          color: rgba(0, 0, 0, 0.85);
          margin-right: 12px;
        }
        .header_tabs {
          margin-left: 70px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: inherit;
          .tab_item {
            // color: #42C088;
            cursor: pointer;
            margin-right: 16px;

            font-size: 14px;
            flex: auto;
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            .tab_active {
              position: absolute;
              bottom: 0;
              width: 28px;
              height: 2px;
              background-color: #42c088;
              color: #42c088;
              border-radius: 1px;
            }
            .tab_text {
              display: flex;
              justify-content: center;
              font-weight: 700;
              color: #000;
              height: 100%;
              padding: 0 16px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            &:hover .tab_text {
              color: #42c088;
            }
          }
        }
      }
      .header_right {
        display: flex;
        align-items: center;
        .header_logout {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 22px;
        }
        .user-msg {
          display: flex;
          .icon-style {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            .user_logo {
              width: 100%;
              height: 100%;
            }
          }
          .user_name {
            margin: 0 10px;
          }
        }
      }
    }
  }
  .footer_wrapper {
    // position: sickcy;
    // left: 0;
    // bottom: 0;
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #f5f6fa;
    .footer_content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 1200px;
      margin: 0 auto;
      position: relative;
      border-bottom: 1px solid #eff1f6;
      .footer_left {
        width: 450px;
        height: 178px;
        padding-top: 28px;
        display: inline-block;
        .footer_logo {
          width: 95px;
          height: 42px;
          margin-bottom: 12px;
        }
        p {
          font-family: PingFangSC-Regular;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.85);
          font-weight: 400;
          margin-bottom: 12px;
        }
      }
      .footer_right {
        width: 340px;
        height: 178px;
        display: inline-flex;
        position: absolute;
        right: 0;
        .footer_right_item {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 112px;
          margin-top: 28px;
          .right_item_img {
            width: 98px;
            height: 98px;
            margin-bottom: 8px !important;
          }
        }
      }
    }
    .footer_bottom {
      width: 1200px;
      min-height: 100px;
      margin: 0 auto;
      padding-top: 24px;
      padding-bottom: 24px;
      text-align: center;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
      font-weight: 400;
      .footer_bottom_content {
        margin-top: 12px;
        font-size: 12px;
        a {
          color: rgba(0, 0, 0, 0.45);
        }
      }
    }
  }
}
</style>
