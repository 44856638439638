<template>
  <el-dialog width="740px" :footer="false" :visible.sync="dialogVisible" title="验证码登录">
    <span slot="title"></span>
    <div class="dialog_wrapper">
      <div class="dialog_left">
        <img src="@/assets/images/login.png" />
      </div>
      <div class="dialog_right">
        <div class="close_box">
          <a-icon
            type="close"
            style="font-size: 18px; color: #c1c1c1; cursor: pointer"
            @click="dialogVisible = false"
          />
        </div>
        <div class="login_title">登录/注册</div>
        <div class="login_form">
          <div class="form_item">
            <div class="form_input_box">
              <input v-model="form.phone" placeholder="请输入手机号" type="text" />
            </div>
          </div>
          <div class="form_item">
            <div class="form_input_box">
              <input v-model="form.code" placeholder="请输入验证码" type="text" />
            </div>
            <div class="form_code" @click="getCode">{{ buttonText }}</div>
          </div>
          <el-checkbox style="margin-top: 15px" v-model="checked"
            ><span style="color: #8c8c8c">已阅读并同意</span
            ><span class="LoginCollection_policy__xJq6k">《隐私政策》</span
            ><span class="LoginCollection_policy__xJq6k">《服务协议》</span></el-checkbox
          >
          <el-button style="width: 100%; margin-top: 15px; background-color: #42c088; color: #fff" @click="login"
            >登录/注册</el-button
          >
        </div>
      </div>
    </div>
    <!-- <el-form :model="form" label-width="80px">
      <el-form-item label="手机号">
        <el-input v-model="form.phone"></el-input>
      </el-form-item>
      <el-form-item label="验证码">
        <div style="display: flex">
          <el-input v-model="form.code" style="width: 70%"></el-input>
          <el-button style="flex: 1; margin-left: 20px" :disabled="countdown > 0" @click="getCode">{{
            buttonText
          }}</el-button>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button :disabled="!form.code || !form.phone" type="primary" @click="login">确 定</el-button>
    </div> -->
  </el-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { getCode, getLogin, getUserInfo } from '@/api/home.js'
export default {
  data() {
    return {
      dialogVisible: false,
      checked: false,
      form: {
        phone: '',
        code: ''
      },
      countdown: 0,
      buttonText: '获取验证码'
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo
    })
  },
  methods: {
    ...mapMutations(['changeIsLogin', 'changeUserInfo', 'changeUserMessage']),
    getCode() {
      let reg = /^1[3456789]\d{9}$/
      console.log('getCode', !reg.test(this.form.phone))
      if (!reg.test(this.form.phone)) {
        return this.$message.warning('手机号格式错误')
      } else {
        getCode({
          phone: this.form.phone,
          session_key: '',
          uid: 0,
          user_id: 0
        }).then((res) => {
          if (res.code == 1) {
            this.$message.success('验证码发送成功')
          }
          console.log('getCode', res)
        })
        this.sendCode()
      }
    },
    sendCode() {
      // 发送验证码的逻辑
      // ...
      // 设置倒计时
      this.countdown = 60
      const timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--
          this.buttonText = `${this.countdown}秒后重发`
        } else {
          clearInterval(timer)
          this.buttonText = '获取验证码'
        }
      }, 1000)
    },
    login() {
      getLogin({
        code: this.form.code,
        method: 'login.AppLogin',
        phone: this.form.phone,
        session_key: '',
        uid: 0,
        user_id: 0
      }).then((res) => {
        if (res.code == 1) {
          console.log("111");
          this.handleUserMessage();
          console.log("222");
          this.changeIsLogin(true)
          this.changeUserInfo(res.data)
          this.$message.success('登录成功')
          this.dialogVisible = false
          console.log('clickLogin', res)
        }
      })
      // 登录的逻辑
      // ...
      // 关闭弹窗
    },
    handleUserMessage() {
      console.log("koa");
      getUserInfo({
        uid: this.userInfo.user_id || "",
        user_id: this.userInfo.user_id || "",
        session_key: this.userInfo.session_key || "",
      }).then((res) => {
        console.log(res);
        if (res.code == 1) {
          this.changeUserMessage(res.data)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .el-dialog__header {
  display: none;
}
::v-deep .el-dialog__body {
  padding: 6px;
}
.dialog_wrapper {
  display: flex;
  justify-content: start;
  height: 510px;

  // padding: 20px;
  .dialog_left {
    width: 260px;
    border-radius: 8px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .dialog_right {
    width: 480px;
    padding: 20px;
    .close_box {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .login_title {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      // padding: 37px 0 7px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
    }
    .login_form {
      width: 80%;
      margin: 60px auto;
      .LoginCollection_policy__xJq6k {
        color: #42c088;
        cursor: pointer;
      }
      .form_item {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 44px;
        margin-bottom: 24px;
        padding: 0 12px;
        color: rgba(0, 0, 0, 0.25);
        font-weight: 400;
        line-height: 42px;
        border: 1px solid #edeef2;
        .form_input_box {
          flex: 1 1;
          padding: 0;
          input {
            color: rgba(0, 0, 0, 0.85);
            width: 100%;
            font-size: 14px;
            font-weight: 400;
          }
        }
        .form_code {
          position: relative;
          padding-left: 12px;
          color: #42c088;
          font-weight: 400;
          line-height: 20px;
          cursor: pointer;
          &:after {
            content: '';
            position: absolute;
            left: 0;
            width: 1px;
            height: 20px;
            background-color: #edeef2;
          }
        }
      }
    }
  }
}
</style>
