import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
// import Antd from 'ant-design-vue';
// import 'ant-design-vue/dist/antd.css';
import './assets/styles/common.less'
import './assets/styles/reset.less'
import { globalRegister } from './global'
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
Vue.use(Antd);
Vue.config.productionTip = false
Vue.use(globalRegister)
// Vue.use(Antd)
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
